    export default [
    {
        id: 1,
        title: "屈臣氏：做最真实的产品体验，打造零售新势能", 
        // time: "2021年06月29日",
        desc: "流量时代，智蜂助力中国零售百强企业屈臣氏增强媒介宣推，在降本增效的同时达到最佳传播效果。",
        beijin: "屈臣氏，常年是中国零售百强上榜企业，在全球拥有一万多家线下实体店，自有及代理的品牌更是不计其数。上万的零售店曾是其最强的武器，帮助它在“渠道为王”的时代一路披荆斩棘，开疆扩土。",
        celue: "针对屈臣氏品牌及产品特性，智蜂主要针对微博和小红书平台进行博主招募。同时，启用智蜂博主大数据精准匹配美妆、护肤和日化等兴趣的博主，将传播内容精准送达目标群体。引导博主在自媒体平台发布最真实的产品使用体验，帮助屈臣氏进行传播推广。在这个环节中，屈臣氏省去了额外开支，达到亿级曝光。",
        imgs: [require("../assets/brands/id1.png")],
        result: `<div style="color:#f26c00;line-height:normal;text-align:center;display:flex;flex-wrap: wrap;">
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+1亿</div>打造话题热度值</div>
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+1500</div>素人博主总曝光</div>
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+80997</div>品牌近期总浏览量</div>
        </div>`
    },
    {
        id: 2,
        title: "元气森林：圈定目标，塑造新世代饮料品牌", 
        // time: "2021年09月14日",
        desc: "从零到一，与传统饮料强势品牌的博弈之路。",
        beijin: "在饮料行业，无论是品类还是品牌，都在经历着重新洗牌。企业只有快速顺应时代，拥抱变化，才能在如今这个经历着重大变革的市场环境中占据自己的市场份额。",
        celue: "围绕目标消费人群，智蜂X元气森林通过高颜值、日系风格等关键词攫取专属于年轻人的饮料品牌。在投入推广后，智蜂帮助元气森林将目标用户锁定在19-35岁、被市场培养起来健康观念、且更喜欢体验新产品的KOC群体，连锁便利店的冷柜对他们的产品来说，是与合适的消费者相遇的最佳位置。并成功引起了消费群体的关注，终于让元气森林打响了第一炮。推广的不俗表现给“0糖”这个方向打了定心针。",
        imgs: [require("../assets/brands/id2.png")],
        result: `<div style="color:#f26c00;line-height:normal;text-align:center;display:flex;flex-wrap: wrap;">
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+83.12W</div>打造话题热度值</div>
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+200%</div>搜索提升</div>
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+215%</div>相关笔记增长</div>
        </div>`
    },
    {
        id: 3,
        title: "笑也IKAAS：素人博主打造口腔爆款神器", 
        // time: "01月31日",
        desc: "从小鲜肉到爆款冲牙器，智蜂为新品牌突围赛道，塑造产品形象，让KOC助力品牌增长。",
        beijin: "个护电器市场迭代迅速、竞争激烈，前有经典大牌抢占心智，后有新锐品牌争相入局，用户早已眼花缭乱，笑也IKAAS作为未大打的“小鲜肉”品牌，想要实力突围，可谓是挑战重重。",
        celue: "智蜂找到了优质素人博主解析产品卖点，在用户心里建立起“牙医推荐”的专业正畸口腔护理产品印象。产品依靠极强的产品力与“握感顺滑”记忆点，配合精准定位的「劲浪小飞鱼」昵称，让消费者一见倾心。短时间内产品声量提升，形成一阵好评风潮。智蜂成功助力笑也IKAAS抢占冲牙器赛道。        ",
        imgs: [require("../assets/brands/id3.png")],
        result: `<div style="color:#f26c00;line-height:normal;text-align:center;display:flex;flex-wrap: wrap;">
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+63.46W</div>打造话题热度值</div>
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+823</div>素人博主总曝光</div>
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+10379</div>品牌近期总浏览量</div>
        </div>`
    },
    {
        id: 4,
        title: "爱慕哲学：「酒香沐浴油」人间水蜜桃的走红之路", 
        // time: "01月31日",
        desc: "智蜂X爱慕哲学，以女性生活VLOG为场景，自然植入产品【酒香蜜桃】卖点。",
        beijin: `爱慕哲学推出新款沐浴油，主打酒香型蜜桃味，瞄准时下女性喜爱的“人间水蜜桃”话题。智蜂如何帮助爱慕哲学找到营销差异点？提升用户对【amouage】的品牌认知度？本次合作面临着三个挑战：
        1、行业挑战：洗护品牌同质化严重，如何创新？
        2、品牌挑战：大牌当道，小品牌如何破局？
        3、产品挑战：竞品繁多，如何做出特色？
        `,
        celue: "成熟品牌额“种草”营销日渐激烈。新品牌想要每次投放都能有效回报，需要最高效地俘获人心。智蜂根据种草平台算法，以“高质量笔记内容”为核心，找到优质素人博主打造出有趣又有辨识度的种草Vlog视频，提升品牌传播效果。",
        imgs: [require("../assets/brands/id4.png")],
        result: `<div style="color:#f26c00;line-height:normal;text-align:center;display:flex;flex-wrap: wrap;">
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+60.38W</div>打造话题热度值</div>
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">+11836</div>品牌近期总浏览量</div>
        </div>`
    },
    {
        id: 5,
        title: "LIONIA莲妮娜：精准定位，抢占年轻人护肤新贵", 
        // time: "01月31日",
        desc: "智蜂与护肤新贵莲妮娜合作，洞察女性护肤诉求，重新定义护肤体验，成功带动品牌推广升级。",
        beijin: `LIONIA莲妮娜推出“鱼子塑颜焕活多效精华”产品，面临着用户对鱼子酱精华的认知停留在"贵妇价格"上的品牌困局。智蜂与莲妮娜合作，洞察年轻人护肤诉求，根据品牌营销的具体需求，选出最为匹配的KOC达人打造出圈传播，让海洋鱼子塑颜焕活多效精华按摩头设计成为年轻人护肤新体验，推动莲妮娜抢占护肤出圈案例。`,
        celue: "用户急需一款产品“贵妇平替”。然而，市场上多数鱼子酱护肤品牌仍然是被老品牌所占据，“平替”赛道仍是一片蓝海、尚无明星选手。智蜂与莲妮娜合作，选出最为匹配的KOC达人打造出圈传播。洞察年轻人护肤诉求，根据皮“平替”“体验感”，让海洋鱼子塑颜焕活多效精华按摩头设计成为年轻人护肤新体验，推动莲妮娜抢占护肤出圈案例。",
        imgs: [require("../assets/brands/id5.png")],
    },
    {
        id: 6,
        title: "嘉远智汇广场：地产新风向，共创城市未来", 
        // time: "01月31日",
        desc: "促使项目一跃成为航空新城中芯商圈投号资产，升值潜力不可估量",
        beijin: `智汇·广场依托着六大中心、华发商都等城市顶级配套，要在航空新城占取关键位置，如何成就大湾区价值高地，成为嘉远的首要痛点。`,
        celue: "智蜂与嘉远智汇广场在此项目中，可谓强强联合。针对高净值人群围绕“投资、理财”等关键词梳理出一条合适的曝光渠道，联动素人博主、房地产kol进行铺量、实地打卡，最终使嘉远智汇广场在众多新兴地产中一跃成为航空新城中芯商圈投号资产。",
        imgs: [require("../assets/brands/id6_1.png"), require("../assets/brands/id6_2.png")],
        result: `<div style="color:#f26c00;line-height:normal;text-align:center;display:flex;flex-wrap: wrap;">
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">61.92W</div>打造话题热度值</div>
            <div style="margin: 0 30px 30px 0;"><div style="font-size:32px;font-weight:bold;">7532</div>品牌近期总浏览量</div>
        </div>`
    },
];